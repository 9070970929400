<template>
  <window-content v-if="loading" class="financeiro-window">
    <div class="full-width flex items-center justify-center full-height">
      <sl-loading />
    </div>
  </window-content>
  <window-content v-else class="financeiro-window" id="container-printer">
    <div class="w-m-header">
      <pessoa-cadastro ref="cadastro" :mount-body="true" style="z-index: 11000" />
      <div>
        <h2>Registro de Comunicação ID {{registro.id}}</h2>
        <div class="visible-print" style="margin-bottom: 20px"></div>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close hidden-print">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content erp-s-field-bold erp-s-field-m-b">
        <e-row mr>
          <e-col style="max-width: 100px; min-width: 100px">
            <erp-s-field label="ID">
              #{{registro.id}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Data do Registro">
              {{registro.dateRegistry|formatDate}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Data do Envio">
              {{registro.dateSend|formatDate}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Data do Recebimento" icon-help="Esta informação pode não ser confiável dependendo do provedor de recebimento do destinatário">
              {{registro.dateReceiver|formatDate}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Data de Leitura" icon-help="Esta informação pode não ser confiável dependendo do provedor de recebimento do destinatário, se aceita enviar esta informação">
              {{registro.dateReaded|formatDate}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Canal">
              {{registro.type}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Sessão">
              {{registro.session}}
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Assunto">
              {{registro.subject}}
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Nome do Destinatário">
              {{registro.recipientName}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Destinatário">
              {{registro.recipient}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Nome do Remetente">
              {{registro.senderName}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Remetente">
              {{registro.sender}}
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row v-if="registro.person" mr>
          <e-col style="max-width: 100px; width: 100px">
            <erp-s-field label="Pessoa ID">
              {{registro.person.id}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Pessoa">
              {{registro.person.name}}
            </erp-s-field>
          </e-col>
        </e-row>
      </div>

      <div v-if="registro.attachments" class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Anexos</legend>
          </e-col>
        </e-row>
        <e-row>
          <span style="padding: 3px 6px; background-color: #f2f2f2; margin-right: 10px; border-radius: 2px; cursor: default" class="non-selectable" v-for="a in registro.attachments">{{a.name}}</span>
        </e-row>
      </div>

      <div v-if="registro.message" class="body-content erp-s-field-bold erp-s-field-m-b">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Conteúdo da Mensagem</legend>
          </e-col>
        </e-row>
        <e-row style="background-color: #333333">
          <div class="print-wrap" style="border: #f2f2f2 1px dotted; background-color: #FFFFFF; width: 100%; max-width: 800px; margin: 20px auto; border-radius: 5px; padding: 12px">
            <div v-html="registro.message"></div>
          </div>
        </e-row>
      </div>

      <div v-if="registro.messageText" class="body-content erp-s-field-bold erp-s-field-m-b">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Mensagem em versão de texto <small>se existir</small></legend>
          </e-col>
        </e-row>
        <e-row>
          {{registro.messageText}}
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions hidden-print">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Fechar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="print"  icon="print" label="Imprimir" no-caps
               :color="'blue-grey-5'"
               class="b-radius-3px no-border-radius-right__"/>
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import {findHistoricoComunicacao as find} from "@/domain/pessoa/domain"
import {VMoney} from "v-money"
import PessoaCadastro from '@/components/pessoa/components/window/Cadastro'
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"
import SlLoading from "components/layout/components/Loading.vue"

export default {
  name: 'ComWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [GlobalPersonMixin],
  directives: {money: VMoney},
  components: {
    ErpSField,
    SlLoading,
    PessoaCadastro,
    ERow,
    ECol,
    UTooltip,
    WindowContent,
  },
  props: ['id', 'options', 'router'],
  data() {
    return {
      loading: true,
      preventBind: true,
      registro: null
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {},
  computed: {
  },
  methods: {
    load() {
      this.loading = true
      find(this.id)
          .then(response => {
            this.registro = response.data
            this.$nextTick(() => {
              setTimeout(() => {
                this.loading = false
              }, 1)
            })
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    editPessoa(id) {
      this.$refs.cadastro.show(id)
    },
    print() {
      const areaPrint = document.createElement('div')
      areaPrint.classList.add('removeAfterPrint')
      //const printer = document.querySelector('#container-printer')
      const printer = document.querySelector('#container-printer')
      const printerCopy = printer.cloneNode(true)
      printerCopy.classList.add('removeAfterPrint')
      areaPrint.appendChild(printerCopy)
      document.body.appendChild(areaPrint)
      document.body.classList.add('print')
      const app = document.querySelector('#uloc-app')
      app.classList.add('hide')
      app.classList.add('print-view-active')
      printer.classList.add('hidden-print')
      this.$nextTick(() => {
        window.print()
        setTimeout(() => {
          document.body.classList.remove('print')
          app.classList.remove('hide')
          app.classList.remove('print-view-active')
          printer.classList.remove('hidden-print')
          let nodeToRemove = document.querySelector('.removeAfterPrint')
          document.body.removeChild(nodeToRemove)
        }, 50)
      })
    }
  }
}
</script>
